<template>
  <div id="home-layout">
    <slot />
    <Alert
      :snackbar="snackbar"
      :type="alertType"
      :text="alertText"
      @close="onCloseAlert"
    />
  </div>
</template>

<script>
import { isEmpty } from "lodash";

import Alert from "@/components/Alert.vue";

import Utils from "@/mixin";

export default {
  mixins: [Utils],
  components: {
    Alert,
  },
  computed: {
    snackbar() {
      return !isEmpty(this.alert?.message) ? true : false;
    },
    alertText() {
      return !isEmpty(this.alert) ? this.alert?.message : "";
    },
    alertType() {
      return !isEmpty(this.alert) ? this.alert?.type : "";
    },
  },
  methods: {
    onCloseAlert() {
      this.alert.message = "";
    },
  },
};
</script>

<style lang="scss" scoped>
#home-layout {
  min-height: 100vh;
  .home-logo {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }
}
</style>