<template>
  <v-app>
    <component :is="layout">
      <v-main>
        <router-view />
      </v-main>
    </component>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || "defaultLayout";
    },
  },
};
</script>

<style lang="scss">
$light-gray: #f6f6f6;

.v-main {
  background: $light-gray;
  height: 100%;
}
</style>
