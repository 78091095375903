<template>
  <div class="alert-container">
    <v-snackbar
      v-model="modal"
      timeout="3000"
      top
      right
      :color="type"
      @input="$emit('close')"
      >{{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  computed: {
    modal: {
      get() {
        return this.snackbar;
      },
      set() {
        return this.snackbar;
      },
    },
  },
  props: {
    snackbar: {
      type: [String, Boolean],
    },
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "error",
    },
  },
};
</script>